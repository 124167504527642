.structure-browser-button .material-icons:before {
   content: "Z";
}
#structure-browser-panel {
   min-width: 300px;
   min-height: 400px;
}
#structure-browser-panel .treeview group:not(.collapsed) {
   display: block;
}
#structure-browser-panel .treeview group {
   padding-left: 20px;
}
#structure-browser-panel .treeview leaf {
   padding-left: 20px;
}
#structure-browser-panel .docking-panel-scroll {
   height: calc(100% - 104px);
   overflow-x: hidden;
}
#structure-browser-panel #structure-browser-panelViewerModelStructurePanel-Searchbox .docking-panel-scroll {
   height: calc(100% - 20px);
}
#structure-browser-panel #structure-browser-panel-scroll-container group.active lmvheader * {
   color: #1DC6F2;
}