.custom-properties-button .material-icons:before {
   content: "O";
}
#custom-properties {
   min-width: 500px;
   min-height: 500px;
}
#custom-properties #custom-properties-scroll-container #loading-spinner {
   position: absolute;
   left: 0;
   top: 0;
   width: 100%;
   height: 100%;
   background-color: black;
   opacity: 0.7;
   text-align: center;
   padding-top: 2em;
}