.adsk-viewing-viewer.dark-theme .adsk-button.active,
.adsk-viewing-viewer.dark-theme .adsk-button:focus {
    color: #3b8fcd !important;
}

.adsk-viewing-viewer.dark-theme .adsk-button:hover {
    color: #3b8fcd !important;
    border: 1px solid #3b8fcd !important;
}

@media screen and (max-width: 959px) {
    .adsk-viewing-viewer .adsk-toolbar {
        display: none !important;
    }
}

.adsk-viewing-viewer .adsk-toolbar {
    transform: scale(0.8) !important;
}

sup {
    font-size: 70%;
}

body {
    padding-right: 0 !important;
}
@font-face {
    font-family: "icomoon";
    src: url("../assets/icons/icomoon.eot?71abci");
    src: url("../assets/icons/icomoon.eot?71abci#iefix") format("embedded-opentype"),
        url("../assets/icons/icomoon.ttf?71abci") format("truetype"),
        url("../assets/icons/icomoon.woff?71abci") format("woff"),
        url("../assets/icons/icomoon.svg?71abci#icomoon") format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^="icon-markups-"],
[class*=" icon-markups-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: "icomoon" !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Enable Ligatures ================ */
    letter-spacing: 0;
    -webkit-font-feature-settings: "liga";
    -moz-font-feature-settings: "liga=1";
    -moz-font-feature-settings: "liga";
    -ms-font-feature-settings: "liga" 1;
    font-feature-settings: "liga";
    -webkit-font-variant-ligatures: discretionary-ligatures;
    font-variant-ligatures: discretionary-ligatures;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
